*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}
